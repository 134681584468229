import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import {
  Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, FormControl, InputLabel,
  Tab, Tabs, Box
} from '@mui/material';

interface ManualTransactionRequest {
  id: string;
  type: 'credit' | 'debit';
  userId: string;
  amount: number;
  currency: string;
  status: 'pending' | 'approved' | 'rejected';
  createdBy: string;
  approvedBy?: string;
  createdAt: string;
  updatedAt: string;
}

interface ApprovalQueueItem {
  id: string;
  type: 'deposit' | 'withdrawal' | 'add_withdrawal_address' | 'add_deposit_address';
  kytRiskScore: number;
  status: 'pending' | 'approved' | 'rejected';
  details: string;
  kytUrl: string;
}

interface HistoricalApprovedEvent extends ApprovalQueueItem {
  approvedBy: string;
  approvedAt: string;
}

interface TransactionRequestsProps {
  socket: Socket | null;
}

const TransactionRequests: React.FC<TransactionRequestsProps> = ({ socket }) => {
  const [tabValue, setTabValue] = useState(0);
  const [manualRequests, setManualRequests] = useState<ManualTransactionRequest[]>([]);
  const [approvalQueue, setApprovalQueue] = useState<ApprovalQueueItem[]>([]);
  const [historicalEvents, setHistoricalEvents] = useState<HistoricalApprovedEvent[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newRequest, setNewRequest] = useState<Partial<ManualTransactionRequest>>({
    type: 'credit',
    userId: '',
    amount: 0,
    currency: 'USD',
  });
  const [selectedItem, setSelectedItem] = useState<ApprovalQueueItem | null>(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);

  useEffect(() => {
    if (socket) {
      socket.emit('getManualTransactionRequests');
      socket.emit('getApprovalQueue');
      socket.emit('getHistoricalApprovedEvents');

      socket.on('manualTransactionRequests', (data: ManualTransactionRequest[]) => {
        setManualRequests(data);
      });

      socket.on('approvalQueue', (data: ApprovalQueueItem[]) => {
        setApprovalQueue(data);
      });

      socket.on('historicalApprovedEvents', (data: HistoricalApprovedEvent[]) => {
        setHistoricalEvents(data);
      });
    }

    return () => {
      if (socket) {
        socket.off('manualTransactionRequests');
        socket.off('approvalQueue');
        socket.off('historicalApprovedEvents');
      }
    };
  }, [socket]);

  const handleCreateRequest = () => {
    if (socket) {
      socket.emit('createManualTransactionRequest', newRequest);
      setOpenDialog(false);
      setNewRequest({
        type: 'credit',
        userId: '',
        amount: 0,
        currency: 'USD',
      });
    }
  };

  const handleApprove = (id: string) => {
    if (socket) {
      socket.emit('approveTransactionRequest', id);
    }
  };

  const handleReject = (id: string) => {
    if (socket) {
      socket.emit('rejectTransactionRequest', id);
    }
  };

  const handleApproveQueueItem = (id: string) => {
    if (socket) {
      socket.emit('approveQueueItem', id);
    }
  };

  const handleItemClick = (item: ApprovalQueueItem) => {
    setSelectedItem(item);
    setOpenDetailsDialog(true);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Transaction Requests and Approvals
      </Typography>
      <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
        <Tab label="Manual Transaction Requests" />
        <Tab label="Approval Queue" />
        <Tab label="Historical Approved Events" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)} sx={{ mb: 2 }}>
          Add Manual Transaction Request
        </Button>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Approved By</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manualRequests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{request.id}</TableCell>
                  <TableCell>{request.type}</TableCell>
                  <TableCell>{request.userId}</TableCell>
                  <TableCell>{request.amount}</TableCell>
                  <TableCell>{request.currency}</TableCell>
                  <TableCell>{request.status}</TableCell>
                  <TableCell>{request.createdBy}</TableCell>
                  <TableCell>{request.approvedBy || '-'}</TableCell>
                  <TableCell>{new Date(request.createdAt).toLocaleString()}</TableCell>
                  <TableCell>{new Date(request.updatedAt).toLocaleString()}</TableCell>
                  <TableCell>
                    {request.status === 'pending' && (
                      <>
                        <Button onClick={() => handleApprove(request.id)}>Approve</Button>
                        <Button onClick={() => handleReject(request.id)}>Reject</Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>KYT Risk Score</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {approvalQueue.map((item) => (
                <TableRow key={item.id} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.kytRiskScore}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>
                    {item.status === 'pending' && (
                      <Button onClick={(e) => { e.stopPropagation(); handleApproveQueueItem(item.id); }}>
                        Approve
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>KYT Risk Score</TableCell>
                <TableCell>Approved By</TableCell>
                <TableCell>Approved At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historicalEvents.map((event) => (
                <TableRow key={event.id}>
                  <TableCell>{event.id}</TableCell>
                  <TableCell>{event.type}</TableCell>
                  <TableCell>{event.kytRiskScore}</TableCell>
                  <TableCell>{event.approvedBy}</TableCell>
                  <TableCell>{new Date(event.approvedAt).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New Manual Transaction Request</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Type</InputLabel>
            <Select
              value={newRequest.type}
              onChange={(e) => setNewRequest({ ...newRequest, type: e.target.value as 'credit' | 'debit' })}
            >
              <MenuItem value="credit">Credit</MenuItem>
              <MenuItem value="debit">Debit</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="User ID"
            value={newRequest.userId}
            onChange={(e) => setNewRequest({ ...newRequest, userId: e.target.value })}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Amount"
            type="number"
            value={newRequest.amount}
            onChange={(e) => setNewRequest({ ...newRequest, amount: parseFloat(e.target.value) })}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Currency</InputLabel>
            <Select
              value={newRequest.currency}
              onChange={(e) => setNewRequest({ ...newRequest, currency: e.target.value })}
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
              <MenuItem value="GBP">GBP</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateRequest} color="primary">Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDetailsDialog} onClose={() => setOpenDetailsDialog(false)}>
        <DialogTitle>Approval Queue Item Details</DialogTitle>
        <DialogContent>
          {selectedItem && (
            <>
              <Typography>ID: {selectedItem.id}</Typography>
              <Typography>Type: {selectedItem.type}</Typography>
              <Typography>KYT Risk Score: {selectedItem.kytRiskScore}</Typography>
              <Typography>Status: {selectedItem.status}</Typography>
              <Typography>Details: {selectedItem.details}</Typography>
              <Typography>
                KYT URL: <a href={selectedItem.kytUrl} target="_blank" rel="noopener noreferrer">{selectedItem.kytUrl}</a>
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDetailsDialog(false)}>Close</Button>
          {selectedItem && selectedItem.status === 'pending' && (
            <Button onClick={() => { handleApproveQueueItem(selectedItem.id); setOpenDetailsDialog(false); }}>
              Approve
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

const TabPanel: React.FC<{ children?: React.ReactNode; value: number; index: number }> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default TransactionRequests;
