import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  Box,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl, ListItemIcon, IconButton,
} from '@mui/material';
import { formatUnixTimestamp } from '../util/DateFormat';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';

interface Asset {
  id: number;
  created_at: number;
  updated_at: number;
  name: string;
  asset: string;
  chain: string;
  chain_id: number;
  contract_address: string;
  contract_decimals: number;
}

interface Chain {
  id: number;
  created_at: number;
  updated_at: number;
  name: string;
  is_utxo: boolean;
}

interface TransactionServerConfigProps {
  socket: Socket | null;
}

const TransactionServerConfig: React.FC<TransactionServerConfigProps> = ({ socket }) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [chains, setChains] = useState<Chain[]>([]);
  const [deleteChainDialog, setDeleteChainDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openAssetDialog, setOpenAssetDialog] = useState(false);
  const [deleteAssetDialog, setDeleteAssetDialog] = useState(false);
  const [assetToDelete, setAssetToDelete] = useState<Asset | null>(null);
  const [chainToDelete, setChainToDelete] = useState<Chain | null>(null);
  const [openChainDialog, setOpenChainDialog] = useState(false);
  const [newAsset, setNewAsset] = useState<Partial<Asset>>({});
  const [newChain, setNewChain] = useState<Partial<Chain>>({});

  const handleDeleteAssetConfirmation = (asset: Asset) => {
    setAssetToDelete(asset);
    setDeleteAssetDialog(true);
  };
  const handleDeleteChainConfirmation = (chain: Chain) => {
    setChainToDelete(chain);
    setDeleteChainDialog(true);
  };

  useEffect(() => {
    if (socket) {
      socket.emit('getTransactionServerConfig');
      socket.on('transactionServerConfig', (config: { assets: Asset[], chains: Chain[] }) => {
        setAssets(config.assets);
        setChains(config.chains);
      });
    }

    return () => {
      if (socket) {
        socket.off('transactionServerConfig');
      }
    };
  }, [socket]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleAddAsset = () => {
    if (socket && newAsset.name && newAsset.chain_id) {
      socket.emit('addTransactionServerAsset', newAsset, (response: { success: boolean, asset?: Asset }) => {
        if (response.success && response.asset) {
          setAssets([...assets, response.asset]);
          setOpenAssetDialog(false);
          setNewAsset({});
        }
      });
    }
  };

  const handleDeleteAsset = () => {
    if (socket && assetToDelete) {
      socket.emit('deleteTransactionServerAsset', assetToDelete.id, (response: { success: boolean }) => {
        if (response.success) {
          setAssets(assets.filter(asset => asset.id !== assetToDelete.id));
          setDeleteAssetDialog(false);
          setAssetToDelete(null);
        }
      });
    }
  }

  const handleAddChain = () => {
    if (socket && newChain.name) {
      socket.emit('addTransactionServerChain', newChain, (response: { success: boolean, chain?: Chain }) => {
        if (response.success && response.chain) {
          setChains([...chains, response.chain]);
          setOpenChainDialog(false);
          setNewChain({});
        }
      });
    }
  };

  const handleDeleteChain = () => {
    if (socket && chainToDelete) {
      socket.emit('deleteTransactionServerChain', chainToDelete.id, (response: { success: boolean }) => {
        if (response.success) {
          setChains(chains.filter(c => c.id !== chainToDelete.id));
          setDeleteChainDialog(false);
          setChainToDelete(null);
        }
      });
    }
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Transaction Server Configuration
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="configuration tabs">
        <Tab label="Assets" />
        <Tab label="Chains" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Button variant="contained" onClick={() => setOpenAssetDialog(true)} sx={{ mb: 2 }}>
          Add Asset
        </Button>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Asset</TableCell>
                <TableCell>Chain</TableCell>
                <TableCell>Contract Address</TableCell>
                <TableCell>Contract Decimals</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assets.map((asset) => (
                <TableRow key={asset.id}>
                  <TableCell>{asset.id}</TableCell>
                  <TableCell>{asset.name}</TableCell>
                  <TableCell>{asset.asset}</TableCell>
                  <TableCell>{asset.chain}</TableCell>
                  <TableCell>{asset.contract_address}</TableCell>
                  <TableCell>{asset.contract_decimals}</TableCell>
                  <TableCell>{formatUnixTimestamp(asset.created_at)}</TableCell>
                  <TableCell>{formatUnixTimestamp(asset.updated_at)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteAssetConfirmation(asset)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Button variant="contained" onClick={() => setOpenChainDialog(true)} sx={{ mb: 2 }}>
          Add Chain
        </Button>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>UTXO</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chains.map((chain) => (
                <TableRow key={chain.id}>
                  <TableCell>{chain.id}</TableCell>
                  <TableCell>{chain.name}</TableCell>
                  <TableCell>{chain.is_utxo ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{formatUnixTimestamp(chain.created_at)}</TableCell>
                  <TableCell>{formatUnixTimestamp(chain.updated_at)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteChainConfirmation(chain)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <Dialog open={deleteAssetDialog} onClose={() => setDeleteAssetDialog(false)}>
        <DialogTitle>Delete Asset</DialogTitle>
        <DialogContent>
          <Typography>Are you sure? Asset will be marked as inactive in database</Typography>
          {assetToDelete && (
            <Box mt={2}>
              <Typography variant="subtitle1">Asset Details:</Typography>
              <Typography>ID: {assetToDelete.id}</Typography>
              <Typography>Name: {assetToDelete.name}</Typography>
              <Typography>Asset: {assetToDelete.asset}</Typography>
              <Typography>Chain: {assetToDelete.chain}</Typography>
              <Typography>Contract Address: {assetToDelete.contract_address}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAssetDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteAsset}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteChainDialog} onClose={() => setDeleteChainDialog(false)}>
        <DialogTitle>Delete Chain</DialogTitle>
        <DialogContent>
          <Typography>Are you sure? Chain will be marked as inactive in database</Typography>
          {chainToDelete && (
            <Box mt={2}>
              <Typography variant="subtitle1">Chain Details:</Typography>
              <Typography>ID: {chainToDelete.id}</Typography>
              <Typography>Name: {chainToDelete.name}</Typography>
              <Typography>UTXO: {chainToDelete.is_utxo}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteChainDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteChain}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAssetDialog} onClose={() => setOpenAssetDialog(false)}>
        <DialogTitle>Add New Asset</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={newAsset.name || ''}
            onChange={(e) => setNewAsset({ ...newAsset, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Asset"
            fullWidth
            value={newAsset.asset || ''}
            onChange={(e) => setNewAsset({ ...newAsset, asset: e.target.value})}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Chain</InputLabel>
            <Select
              value={newAsset.chain_id || ''}
              onChange={(e) => setNewAsset({ ...newAsset, chain_id: e.target.value as number })}
            >
              {chains.map((chain) => (
                <MenuItem key={chain.id} value={chain.id}>{chain.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Contract Address"
            fullWidth
            value={newAsset.contract_address || ''}
            onChange={(e) => setNewAsset({ ...newAsset, contract_address: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Contract Decimals"
            fullWidth
            type="number"
            value={newAsset.contract_decimals || ''}
            onChange={(e) => setNewAsset({ ...newAsset, contract_decimals: parseInt(e.target.value) })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAssetDialog(false)}>Cancel</Button>
          <Button onClick={handleAddAsset}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openChainDialog} onClose={() => setOpenChainDialog(false)}>
        <DialogTitle>Add New Chain</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={newChain.name || ''}
            onChange={(e) => setNewChain({ ...newChain, name: e.target.value })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newChain.is_utxo || false}
                onChange={(e) => setNewChain({ ...newChain, is_utxo: e.target.checked })}
              />
            }
            label="Is UTXO"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChainDialog(false)}>Cancel</Button>
          <Button onClick={handleAddChain}>Add</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default TransactionServerConfig;
